<template>
    <div>
        <Header :activeIndex="1"></Header>
        <div :style="{minHeight: (minHeight - 330) + 'px'}">
            <div class="banner">
                <img class="bigImg" src="@/assets/images/officialWebsite/news/news-banner.png" alt="天游">
                <img class="smaImg" src="@/assets/images/officialWebsite/news/news-mbanner.png" alt="天游">
            </div>
            <div class="newsDetail" v-if="newsTitle">
                <div class="newsDetail-title">
                    <h3>{{newsTitle}}</h3>
                    <div><i>{{newsDate}}</i><span>{{pageViews}} 次阅读</span></div>
                </div>
                <div class="newsDetail-con" v-html="newsContent">{{newsContent}}</div>
            </div>
            <div class="newsInfo_noCon" v-else>
                <img src="../../../assets/images/officialWebsite/news/news-nocontent.png" alt="">
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    export default {
        name: "NewsDetail",
        data() {
            return {
                newsTitle: '', // 文章标题
                newsDate: '', // 文章日期
                pageViews: '', // 文章阅读量
                newsContent: '', // 文章内容
                minHeight: 0,
            }
        },
        created() {
            this.getItem()
        },
        mounted() {
            this.minHeight = document.documentElement.clientHeight;
            window.onresize = () => {
                this.minHeight = document.documentElement.clientHeight;
            }
        },
        methods: {
            getItem () {
                this.$API.getNewsDetail(this.$route.params).then((res)=>{
                    if (res.data.status == 1) {
                        this.newsTitle = res.data.data.news_infos.title
                        this.newsDate = res.data.data.news_infos.date
                        this.pageViews = res.data.data.news_infos.pageviews
                        this.newsContent = res.data.data.news_infos.details
                    }
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    @media screen and (min-width: 1200px) {
        .banner {
            .bigImg {
                width: 1200px;
                height: 480px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .newsDetail {
            width: 1200px;
            margin: 0 auto;
            .newsDetail-title {
                width: 1120px;
                height: 206px;
                margin: 0 auto;
                h3 {
                    font-size: 28px;
                }
                div {
                    margin-top: 26px;
                    i {}
                    span {}
                }
            }
            .newsDetail-con {
                width: 1120px;
                margin: 48px auto 120px;
            }
        }
    }

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        .banner {
            .bigImg {
                width: 100%;
            }
        }
        .newsDetail {
            width: 92%;
            margin: 0 auto ;
            .newsDetail-title {
                height: 200px;
                h3 {
                    font-size: 28px;
                }
                div {
                    margin-top: 20px;
                    i {}
                    span {}
                }
            }
            .newsDetail-con {
                margin: 48px auto 120px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .banner {
            height: 260px !important;
            .bigImg {
                display: none;
            }
            .smaImg {
                display: block!important;
                width: 100%;
                height: 260px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .newsDetail {
            width: 92%;
            margin: 0 auto;
            .newsDetail-title {
                height: 150px;
                h3 {
                    font-size: 24px;
                }
                div {
                    i {
                    }
                    span {
                        margin-left: 30px!important;
                    }
                }
            }
            .newsDetail-con {
                margin: 32px auto 50px;
            }
        }
    }

    div,img,h3 {margin: 0;padding: 0}
    .banner {
        width: 100%;
        background: #2F333D;
        height: 480px;
        position: relative;
        .bigImg {
            height: 480px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }
        .smaImg {
            display: none;
        }
    }
    .newsDetail {
        .newsDetail-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #E6E6E6;
            margin-bottom: 28px;
            h3 {
                color: #2F333D;
            }
            div {
                color: #ADADB0;
                font-size: 16px;
                height: 22px;
                line-height: 22px;
                margin-top: 26px;
                i {
                    background-image: url("../../../assets/images/officialWebsite/news/news-icon.png");
                    background-repeat: no-repeat;
                    background-size: 18px 18px;
                    background-position: left center;
                    padding-left: 26px;
                    font-style: normal;
                }
                span {
                    margin-left: 64px;
                }
            }
        }

        .newsDetail-con {
            /deep/ img {
                width: 100%!important;
                height: 100%!important;
            }
            /deep/ p {
                line-height: 35px!important;
            }
        }
    }
    .newsInfo_noCon {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        img {
            width: 270px;
            height: 200px;
        }
    }

</style>
